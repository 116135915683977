<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="News Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="News Title">
                <validation-provider
                  #default="{ errors }"
                  name="News Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="news.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="News Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Publisher">
                <b-form-input
                  v-model="news.publisher"
                  placeholder="Publisher"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group LABEL="Link Type">
                <v-select
                  v-model="news.linkType"
                  placeholder="Select Link Type"
                  :options="['Internal', 'External', 'PDF']"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <div class="my-1" v-if="news.thumbnailImg">
                <b-img v-bind="mainProps" :src="news.thumbnailImg"></b-img>
                <a
                  @click="removeThumbMedia(null)"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
              <ComponentMediaSelector
              :params="{lang: null}"
                :isMultiple="false"
                buttonText="Select Thumbnail"
                @mediaSelected="handleThumbMediaSelect"
              />
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-textarea
                  placeholder="Description"
                  v-model="news.description"
                  rows="3"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card title="Content" v-if="news.linkType === 'Internal'">
      <quill-editor v-model="news.content" :options="snowOption" />
    </b-card>

    <b-card
      v-if="news.linkType === 'External' || news.linkType === 'PDF'"
      title="External Link"
    >
      <b-form-group label="Link to the news">
        <b-form-input v-model="news.redirectUrl"></b-form-input>
      </b-form-group>
    </b-card>

    <b-card title="News Date">
      <b-row>
        <b-col class="text-center">
          <b-form-group label="News Date">
            <b-calendar v-model="news.newsDate" block locale="en-US" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Gallery">
      <div class="mb-1 hotel-gallery" v-if="news.gallery.length > 0">
        <div
          class="gallery-item"
          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
          v-for="(media, index) in news.gallery"
          :key="media.key"
        >
          <div class="actions">
            <b-button
              size="sm"
              @click="removeNewsGalleryMedia(null, index)"
              variant="gradient-danger"
              class="btn-icon"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
          <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
        </div>
      </div>
      <ComponentMediaSelector
      :params="{lang: null}"
        :selectedMediaFiles="news.gallery"
        @mediaSelected="handleMediaSelect"
      />
    </b-card>

    <b-card title="News Highlights">
      <b-form
        ref="highLightsform"
        class="repeater-form"
        @submit.prevent="repeatHighLight(null)"
      >
        <b-row
          v-for="(highlight, index) in news.highlights"
          :id="highlight.id"
          :key="highlight.id"
          ref="highlightrow"
        >
          <b-col md="9">
            <quill-editor
              v-model="news.highlights[index]"
              :options="snowOption"
            />
          </b-col>

          <b-col cols="3" class="align-self-center">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeHighlightItem(null, index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Remove</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatHighLight(null)"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                news.highlights.length === 0 ? "Add Highlights" : "Add New"
              }}</span>
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </b-card>

      </b-tab>

       <b-tab title="Hindi">
        <b-card title="News Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="News Title">
                  <b-form-input
                    v-model="news.translation.hi.title"
                    placeholder="News Title"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Publisher">
                <b-form-input
                  v-model="news.translation.hi.publisher"
                  placeholder="Publisher"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group LABEL="Link Type">
                <v-select
                  v-model="news.translation.hi.linkType"
                  placeholder="Select Link Type"
                  :options="['Internal', 'External', 'PDF']"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <div class="my-1" v-if="news.translation.hi.thumbnailImg">
                <b-img v-bind="mainProps" :src="news.translation.hi.thumbnailImg"></b-img>
                <a
                  @click="removeThumbMedia('hi')"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
              <ComponentMediaSelector
              :params="{lang: 'hi'}"
                :isMultiple="false"
                buttonText="Select Thumbnail"
                @mediaSelected="handleThumbMediaSelect"
              />
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-textarea
                  placeholder="Description"
                  v-model="news.translation.hi.description"
                  rows="3"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card title="Content" v-if="news.translation.hi.linkType === 'Internal'">
      <quill-editor v-model="news.translation.hi.content" :options="snowOption" />
    </b-card>

    <b-card
      v-if="news.translation.hi.linkType === 'External' || news.translation.hi.linkType === 'PDF'"
      title="External Link"
    >
      <b-form-group label="Link to the news">
        <b-form-input v-model="news.translation.hi.redirectUrl"></b-form-input>
      </b-form-group>
    </b-card>

    <b-card title="News Date">
      <b-row>
        <b-col class="text-center">
          <b-form-group label="News Date">
            <b-calendar v-model="news.translation.hi.newsDate" block locale="en-US" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Gallery">
      <div class="mb-1 hotel-gallery" v-if="news.translation.hi.gallery.length > 0">
        <div
          class="gallery-item"
          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
          v-for="(media, index) in news.translation.hi.gallery"
          :key="media.key"
        >
          <div class="actions">
            <b-button
              size="sm"
              @click="removeNewsGalleryMedia('hi', index)"
              variant="gradient-danger"
              class="btn-icon"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
          <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
        </div>
      </div>
      <ComponentMediaSelector
      :params="{lang: 'hi'}"
        :selectedMediaFiles="news.translation.hi.gallery"
        @mediaSelected="handleMediaSelect"
      />
    </b-card>

    <b-card title="News Highlights">
      <b-form
        ref="highLightsform"
        class="repeater-form"
        @submit.prevent="repeatHighLight('hi')"
      >
        <b-row
          v-for="(highlight, index) in news.translation.hi.highlights"
          :id="highlight.id"
          :key="highlight.id"
          ref="highlightrow"
        >
          <b-col md="9">
            <quill-editor
              v-model="news.translation.hi.highlights[index]"
              :options="snowOption"
            />
          </b-col>

          <b-col cols="3" class="align-self-center">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeHighlightItem('hi', index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Remove</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatHighLight('hi')"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                news.translation.hi.highlights.length === 0 ? "Add Highlights" : "Add New"
              }}</span>
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </b-card>

      </b-tab>


      <b-tab title="Marathi">
        <b-card title="News Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="News Title">
                  <b-form-input
                    v-model="news.translation.mr.title"
                    placeholder="News Title"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Publisher">
                <b-form-input
                  v-model="news.translation.mr.publisher"
                  placeholder="Publisher"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group LABEL="Link Type">
                <v-select
                  v-model="news.translation.mr.linkType"
                  placeholder="Select Link Type"
                  :options="['Internal', 'External', 'PDF']"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <div class="my-1" v-if="news.translation.mr.thumbnailImg">
                <b-img v-bind="mainProps" :src="news.translation.mr.thumbnailImg"></b-img>
                <a
                  @click="removeThumbMedia('mr')"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
              <ComponentMediaSelector
              :params="{lang: 'mr'}"
                :isMultiple="false"
                buttonText="Select Thumbnail"
                @mediaSelected="handleThumbMediaSelect"
              />
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-textarea
                  placeholder="Description"
                  v-model="news.translation.mr.description"
                  rows="3"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card title="Content" v-if="news.translation.mr.linkType === 'Internal'">
      <quill-editor v-model="news.translation.mr.content" :options="snowOption" />
    </b-card>

    <b-card
      v-if="news.translation.mr.linkType === 'External' || news.translation.mr.linkType === 'PDF'"
      title="External Link"
    >
      <b-form-group label="Link to the news">
        <b-form-input v-model="news.translation.mr.redirectUrl"></b-form-input>
      </b-form-group>
    </b-card>

    <b-card title="News Date">
      <b-row>
        <b-col class="text-center">
          <b-form-group label="News Date">
            <b-calendar v-model="news.translation.mr.newsDate" block locale="en-US" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Gallery">
      <div class="mb-1 hotel-gallery" v-if="news.translation.mr.gallery.length > 0">
        <div
          class="gallery-item"
          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
          v-for="(media, index) in news.translation.mr.gallery"
          :key="media.key"
        >
          <div class="actions">
            <b-button
              size="sm"
              @click="removeNewsGalleryMedia('mr', index)"
              variant="gradient-danger"
              class="btn-icon"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
          <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
        </div>
      </div>
      <ComponentMediaSelector
      :params="{lang: 'mr'}"
        :selectedMediaFiles="news.translation.mr.gallery"
        @mediaSelected="handleMediaSelect"
      />
    </b-card>

    <b-card title="News Highlights">
      <b-form
        ref="highLightsform"
        class="repeater-form"
        @submit.prevent="repeatHighLight('mr')"
      >
        <b-row
          v-for="(highlight, index) in news.translation.mr.highlights"
          :id="highlight.id"
          :key="highlight.id"
          ref="highlightrow"
        >
          <b-col md="9">
            <quill-editor
              v-model="news.translation.mr.highlights[index]"
              :options="snowOption"
            />
          </b-col>

          <b-col cols="3" class="align-self-center">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeHighlightItem('mr', index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Remove</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatHighLight('mr')"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                news.translation.hi.highlights.length === 0 ? "Add Highlights" : "Add New"
              }}</span>
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </b-card>

      </b-tab>
    </b-tabs>

     <b-tab title="Marathi">
        <b-card title="News Details">
      <validation-observer ref="basicValidation">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group label="News Title">
                  <b-form-input
                    v-model="news.translation.mr.title"
                    placeholder="News Title"
                  />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Publisher">
                <b-form-input
                  v-model="news.translation.mr.publisher"
                  placeholder="Publisher"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group LABEL="Link Type">
                <v-select
                  v-model="news.translation.mr.linkType"
                  placeholder="Select Link Type"
                  :options="['Internal', 'External', 'PDF']"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <div class="my-1" v-if="news.translation.mr.thumbnailImg">
                <b-img v-bind="mainProps" :src="news.translation.mr.thumbnailImg"></b-img>
                <a
                  @click="removeThumbMedia('mr')"
                  class="text-danger d-block"
                  href="javascript:void(0);"
                  >Remove Thumbnail</a
                >
              </div>
              <ComponentMediaSelector
              :params="{lang: 'mr'}"
                :isMultiple="false"
                buttonText="Select Thumbnail"
                @mediaSelected="handleThumbMediaSelect"
              />
            </b-col>
            <b-col cols="12">
              <b-form-group label="Description">
                <b-form-textarea
                  placeholder="Description"
                  v-model="news.translation.mr.description"
                  rows="3"
                >
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>

    <b-card title="Content" v-if="news.translation.mr.linkType === 'Internal'">
      <quill-editor v-model="news.translation.mr.content" :options="snowOption" />
    </b-card>

    <b-card
      v-if="news.translation.mr.linkType === 'External' || news.translation.mr.linkType === 'PDF'"
      title="External Link"
    >
      <b-form-group label="Link to the news">
        <b-form-input v-model="news.translation.mr.redirectUrl"></b-form-input>
      </b-form-group>
    </b-card>

    <b-card title="News Date">
      <b-row>
        <b-col class="text-center">
          <b-form-group label="News Date">
            <b-calendar v-model="news.translation.mr.newsDate" block locale="en-US" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-card title="Gallery">
      <div class="mb-1 hotel-gallery" v-if="news.translation.mr.gallery.length > 0">
        <div
          class="gallery-item"
          :class="index == 0 ? 'mr-1 ml-0' : 'mx-1'"
          v-for="(media, index) in news.translation.mr.gallery"
          :key="media.key"
        >
          <div class="actions">
            <b-button
              size="sm"
              @click="removeNewsGalleryMedia('mr', index)"
              variant="gradient-danger"
              class="btn-icon"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
          <b-img v-bind="mainProps" rounded :src="media.src"></b-img>
        </div>
      </div>
      <ComponentMediaSelector
      :params="{lang: 'mr'}"
        :selectedMediaFiles="news.translation.mr.gallery"
        @mediaSelected="handleMediaSelect"
      />
    </b-card>

    <b-card title="News Highlights">
      <b-form
        ref="highLightsform"
        class="repeater-form"
        @submit.prevent="repeatHighLight('mr')"
      >
        <b-row
          v-for="(highlight, index) in news.translation.mr.highlights"
          :id="highlight.id"
          :key="highlight.id"
          ref="highlightrow"
        >
          <b-col md="9">
            <quill-editor
              v-model="news.translation.mr.highlights[index]"
              :options="snowOption"
            />
          </b-col>

          <b-col cols="3" class="align-self-center">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              @click="removeHighlightItem('mr', index)"
            >
              <feather-icon icon="XIcon" class="mr-25" />
              <span>Remove</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            ><b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeatHighLight('mr')"
            >
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>{{
                news.translation.mr.highlights.length === 0 ? "Add Highlights" : "Add New"
              }}</span>
            </b-button></b-col
          >
        </b-row>
      </b-form>
    </b-card>

      </b-tab>
    <b-card>
      <b-button @click="createNews" variant="primary" block size="lg"
        >Create News</b-button
      >
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BTabs, BTab,
  BFormInput,
  BFormGroup,
  BButton,
  BCalendar,
  BTime,
  BFormTextarea,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";
import ComponentMediaSelector from "@/views/Gallery/ComponentMediaSelector";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import axios from "axios";

export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BTabs, BTab,
    BCol,
    BForm,
    BFormInput,
    BFormGroup,
    BButton,
    BCalendar,
    BTime,
    BFormTextarea,
    ComponentMediaSelector,
    BImg,
    quillEditor,
    vSelect,
  },
  data() {
    return {
      required,
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      mainProps: {
        width: 100,
        height: 100,
      },
      news: {
        title: "",
        newsDate: "",
        publisher: "",
        content: "",
        gallery: [],
        description: "",
        highlights: [],
        linkType: "Internal",
        thumbnailImg: "",
        redirectUrl: "",
        translation: {
          hi: {
            title: "",
        newsDate: "",
        publisher: "",
        content: "",
        gallery: [],
        description: "",
        highlights: [],
        linkType: "Internal",
        thumbnailImg: "",
        redirectUrl: "",
          },
          mr: {
            title: "",
        newsDate: "",
        publisher: "",
        content: "",
        gallery: [],
        description: "",
        highlights: [],
        linkType: "Internal",
        thumbnailImg: "",
        redirectUrl: "",
          }
        }
      },
    };
  },
  methods: {
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    handleMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.news.translation[mediaObject.params.lang].gallery = mediaObject.media;
        } else {
        this.news.gallery = mediaObject.media;
        }
      }
    },
    removeNewsGalleryMedia(lang=undefined,index) {
      if(lang ) {
      this.news.translation[lang].gallery.splice(index, 1);
      } else {
      this.news.gallery.splice(index, 1);
      }
    },
    createNews() {
      this.$refs.basicValidation.validate().then((success) => {
        if (success) {
          axios
            .post(`${process.env.VUE_APP_SERVER_URL}/news/create`, this.news, {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            })
            .then((response) => {
              console.log(response.data);
              this.makeToast(
                "success",
                "Success",
                `${response.data.title} has been created successfully!`
              );
            })
            .catch((error) => {
              this.makeToast(
                "danger",
                "Error",
                "Error while creating the hotel!"
              );
              console.log(error.response);
            });
        } else {
          this.makeToast(
            "danger",
            "Error",
            "Please fix the errors to continue."
          );
        }
      });
    },
    repeatHighLight(lang=undefined) {
      if(lang ) {
      this.news.translation[lang].highlights.push("");
      } else {
      this.news.highlights.push("");
      }
    },
    removeHighlightItem(lang=undefined, index) {
      if(lang ) {
      this.news.translation[lang].highlights.splice(index, 1);
      } else {
      this.news.highlights.splice(index, 1);
      }
    },
    handleThumbMediaSelect(mediaObject) {
      if (mediaObject.status) {
        if(mediaObject.params.lang) {
        this.news.translation[mediaObject.params.lang].thumbnailImg = mediaObject.media.src;
        } else {
        this.news.thumbnailImg = mediaObject.media.src;
        }
      }
    },
    removeThumbMedia(lang=undefined) {
      if(lang ) {
      this.news.translation[lang].thumbnailImg = "";
      } else {
      this.news.thumbnailImg = "";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.hotel-gallery {
  display: flex;
  .gallery-item {
    position: relative;

    .actions {
      position: absolute;
      top: 5px;
      right: 10px;
    }
  }
}
</style>